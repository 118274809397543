<template>
    <div class="CS-table" id="CS-table">
        <div class="table">
            <div class="thead">
                <div
                    class="thead__item"
                    ref="thead__item" 
                    v-for="(value, index) in header"
                    :key="index"
                >
                    <div v-if="value.latex" v-katex="value.latex"></div>
					<div v-if="value.units" v-katex="value.units"></div>
                </div>
            </div>
            <div class="tbody">
                <div
                    class="tbody__row"
                    v-for="(columns, rowKey) in content"
                    :key="rowKey"
                >
                    <div
                        v-for="(item, paramId) in columns"    
                        :class="{['tbody__item']: true, ['selectable']: cellSelectable, ['selected']: selectedCell == rowKey && paramId == parameter ? true : false}"
                        ref="tbody__item"
                        :key="paramId"
                        @click="selectCell(rowKey)"
                    >
                        <template v-if="item.value">
                            <template v-if="item.datatype === 'image'">
                                <div class="link-img">
                                    <img :src="item.value">
                                </div>
                            </template>
                            <template v-else-if="item.datatype === 'latex'">
                                <div v-katex="item.value"></div>
                            </template>
                            <template v-else>
                                {{ item.value }}
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: 'CSTable',
    props: {
        id: {
            type: String,
            default: ''
        },
        tableId: {
            type: String,
            default: ''
        },
        cellSelectable: {
            type: Boolean,
            default: false
        },
        exportId: {
            type: String,
            default: ''
        },
        parameter: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            jsonOrder: `{}`,
            jsonData: `{}`,
            selectedCell: ''
        }
    },
    computed: {
        header() {
            let result = [];
            const order = JSON.parse(this.jsonOrder);
            const data = JSON.parse(this.jsonData);

            if (!order?.parameters?.length) return result;

            order.parameters.forEach(parameterId => {
                const indexOfParameter = data.parameter.findIndex(item => item._id == parameterId)

                if(indexOfParameter >= 0) {
                    result.push(data.parameter[indexOfParameter]);
                } else {
                    alert(`Parameter ${parameterId} is not found in data`)
                }
            });

            return result
        },
        content() {
            let result = {};

            const order = JSON.parse(this.jsonOrder);
            const data = JSON.parse(this.jsonData);
            
            if (!order?.rowKeys?.length) return result;

            order.rowKeys.forEach(rowKey => {

                this.header.forEach(parameter => {
                    const paramValue = data.values?.[parameter._id]?.data?.[rowKey] ? data.values[parameter._id].data[rowKey] : '';
                    
                    if (!result[rowKey]) result[rowKey] = {};

                    result[rowKey][parameter._id] = paramValue;
                });

            });

            return result;
        },
        computedTableId() {
            if ( this.id ) return this.id
            if ( this.tableId ) return this.tableId
            return this.$route?.params?.id || 1;
        }
    },
    methods: {
        ...mapActions('table', ['getClientRender']),
        getTableData() {
            this.getClientRender({id: this.computedTableId, exportId: this.exportId}).then((res) => {
                if (res.success) {
                    this.jsonOrder = JSON.stringify(res.data);
                    this.jsonData = JSON.stringify(res.data.tableData);
                } else if (res.error) {
                    console.log('Errors during table load:', res.error);
                }         
            }).catch((err) => console.log(err));
        },
        selectCell(val) {
            if (val == this.selectedCell) {
                this.selectedCell = '';
            } else {
                this.selectedCell = val;
            }

            this.$emit('cell-selected', this.selectedCell);
        }
    },
    mounted() {
        this.getTableData();
    }
}
</script>
<style lang="scss" scoped>
.CS-table {
    width: 100%;
    position: relative;
    overflow-x: auto;
}

.table {
    min-width: max-content;
    height: 100%;
    background: #fff;
    .thead {
        display: flex;
        justify-content: space-between;
        &__item {
            color: white;
            background: #00274f;
            font-family: sans-serif;
            width: 100%;
            text-align: center;
            padding: 10px;
            font-size: 18px;
            border-right: 2px solid #ccc;
            font-weight: 500;
            min-width: 230px;
            &:last-child {
                border: none;
            }
        }
    }
    .tbody {
        min-width: max-content;
        display: flex;
        flex-direction: column;
        &__row {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        &__item {
            width: 100%;
            text-align: center;
            border: 1px solid #ccc;
            padding: 15px;
            font-family: "Roboto";
            font-size: 14px;
            min-width: 230px;

            &.selectable {
                cursor: pointer;
            }

            &.selected {
                background-color: #14AAF5;
                color: #fff;
            }
        }
        .link-img {
            padding: 5px;
            border-radius: 4px;
            
            img {
                display: block;
                max-height: 40px;
                width: auto;
            }
        }
    }
}

</style>
